<template>
  <div class="mypage_search_filter_wrap">
    <!--        필터 + 모달 필터 레이아웃<br>-->
    <div class="search_item_wrap">
      <div class="filter_category_box">
        <select id="category" value="subject" class="w_content select">
          <option value="subject">{{$t('subject')}}</option>
          <option value="content">{{$t('content')}}</option>
          <option value="sub_content">{{$t('sub_content')}}</option>
        </select>
      </div>
      <div class="search_input">
        <input :placeholder="$t('st')"  v-model="searchMsg" @keypress.enter="searchSubmit()"></input>
        <button class="search_btn" @click="searchSubmit()"><span>{{$t('search')}}</span><img src="@/assets/image/main/search_btn.png"></button>
      </div>
      <div class="end_filter">
        <!--            <button class="filter_btn one" @click="openModal('filter-modal')"></button>-->
        <button class="filter_btn two" @click="openModal('filter-modal')"></button>
      </div>
      <div class="filter_sort_box">
        <select id="sort_select" value="update" class="w_content select">
          <option value="update">{{$t('b_update')}}</option>
          <option value="write_date">{{$t('b_enroll')}}</option>
          <option value="read">{{$t('b_enter_m')}}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemFilterDefaultLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>